import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

import GoogleAd from '../components/GoogleAd'

import useSiteMetadata from './SiteMetadata'
import './all.sass'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    margin: theme.spacing(10,0,0,0),
  },
  content: {
    // flexGrow: 1,
    // padding: theme.spacing(3),
  },
}));

const TemplateWrapper = ({ children }) => {
  const { title, titleTemplate, description } = useSiteMetadata()
  const classes = useStyles();

  return (
    <div>
      <Helmet titleTemplate={titleTemplate}>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />

        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <GoogleAd />
      </Helmet>
      <Navbar />
        <main className={classes.toolbar}>
          <div className="Container-root" maxWidth={false}>
            {children}
          </div>
        </main>
        <Footer />
    </div>
  )
}

export default TemplateWrapper
