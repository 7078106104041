import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';


const drawerWidth = 240;

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  window: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  ListItem: {
    padding: 0,
    paddingLeft: theme.spacing(4),
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <Link href="/" color="inherit">
            アイデアマップ
          </Link>
        </Typography>
      </Toolbar>
      <Divider />

      <List aria-label="main menu">

        <ListItem button component="a" href="/ideas/">
          <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>すべて</Typography>}/>
        </ListItem>

        <ListItem button component="a" href="/tags/">
          <ListItemText primary={<Typography type="body2" style={{ fontWeight: 'bold' }}>タグ</Typography>}/>
        </ListItem>

        <List component="nav">
          <ListItem button className={classes.ListItem} component="a" href="/tags/ビジネス">
            <ListItemText primary="ビジネス" />
          </ListItem>
          <ListItem button className={classes.ListItem} component="a" href="/tags/プロモーション">
            <ListItemText primary="プロモーション" />
          </ListItem>
        </List>

      </List>

      <Divider />

      <List component="nav" aria-label="site menu">
        <ListItem button component="a" href="/about/">
          <ListItemText primary="このブログについて" />
        </ListItem>
        <ListItem button component="a" href="/privacy/">
          <ListItemText primary="プライバシーポリシー" />
        </ListItem>
        <ListItem button component="a" href="https://forms.gle/4AGq1pHsPky8Wy8m7">
          <ListItemText primary="お問い合わせ" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <CssBaseline />
      <HideOnScroll {...props}>
      <AppBar position="fixed" className="AppBar-roots">
        <Toolbar>
          <IconButton
            className="Drawer-menu"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <p className="SiteLogo-title">
            <Link href="/">
              アイデアマップ<span>事例から抽出した要素をストックする</span>
            </Link>
          </p>
        </Toolbar>
      </AppBar>
      </HideOnScroll>

      <nav>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.object,
};

export default ResponsiveDrawer;