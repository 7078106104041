import React from 'react'
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

export default function Blog() {
  return (
       <footer className="Footer">

        <Container maxWidth="md">
          <div className="Footer-header">
            <ul className="IconList TextCenter">
              <li><a href="/rss.xml" className="Icon-rss">RSS</a></li>
              <li><a href="https://forms.gle/4AGq1pHsPky8Wy8m7" target="_blank" className="Icon-mail">Mail</a></li>
            </ul>
          </div>
          <div className="Footer-content">
            <ul className="Nav TextCenter">
              <li>
                <Link href="/about">このサイトについて</Link>
              </li>
              <li>
                <Link href="/privacy">プライバシーポリシー</Link>
              </li>
            </ul>
          </div>
          <div className="Footer-footer TextCenter">
            <p>Copyright © 2019 アイデアマップ.</p>
          </div>
        </Container>

       </footer>
  );
}